import React, { useContext } from 'react';

import { DataContext } from '../contexts/data';
import type { DataContextValueType, BookingItem } from '../contexts/data';

function ReviewTable() {
  const {
    bookingData: [bookingData],
    selectedPackage,
    bookingItems: [bookingItems]
  } = useContext(DataContext) as DataContextValueType;

  const getDateFormated = () => {
    const splitedDate = bookingData.extra.bookingDate.split('-');

    const month = parseInt(splitedDate[1], 10) - 1; // Months are 0-based (0 = January)
    const day = parseInt(splitedDate[2], 10);
    const year = parseInt(splitedDate[0], 10);

    const d = new Date(year, month, day);

    const formatedDate = d.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    });

    return formatedDate;
  };

  const getTimeFormated = () => {
    const date = new Date(`${bookingData.extra.bookingDate} ${bookingData.extra.startTime}`);

    return date.toLocaleDateString('en-US', {
      hour12: true,
      hour: 'numeric',
      minute: 'numeric'
    });
  };

  const getFilteredBookingItems = (parentMapItem: BookingItem) => {
    return bookingItems.filter(item => item.parentId === parentMapItem.parentId);
  };

  const checkOptionalProductQuantity = (parentMapItem: BookingItem) => {
    return (
      (getFilteredBookingItems(parentMapItem).length > 1 &&
        parentMapItem.productId === getFilteredBookingItems(parentMapItem)[0].productId) ||
      getFilteredBookingItems(parentMapItem).length === 1
    );
  };

  const checkIncludedProductQuantity = (parentMapItem: BookingItem) => {
    return (
      (getFilteredBookingItems(parentMapItem).length > 1 && parentMapItem.index === 0) ||
      getFilteredBookingItems(parentMapItem).length === 1
    );
  };

  const getAddonsPrice = (): string => {
    if (!bookingItems.length) return '0.00';

    const total = bookingItems
      .filter(subProduct => !subProduct.isIncluded)
      .reduce((acc, item) => {
        return acc + item.cost * item.quantity;
      }, 0);

    return total.toFixed(2);
  };

  return (
    <>
      <div className="review-step__top-info">
        <h5 className="top-info__package-name">{selectedPackage?.name}</h5>
        <div className="top-info__details">
          <p>{getDateFormated()}</p>
          <p>{getTimeFormated().split(',')[1]}</p>
        </div>
      </div>
      <div className="review-step__item-list">
        <div className="review-step__package-label">
          <h3 className="package-label__label">{selectedPackage?.name}</h3>
          <div className="review-step__rows">
            <div className="review-step__row">
              <p className="row-label">Number of jumpers</p>
              <p className="row-value">{bookingData.extra.jumpersNumber}</p>
            </div>
            <div className="review-step__row">
              <p className="row-label">Number of non-jumpers</p>
              <p className="row-value">{bookingData.extra.nonJumpersNumber}</p>
            </div>
          </div>
        </div>
        <div className="review-step__order-item">
          <h4 className="order-item-label">Included</h4>
          <div className="review-step__rows">
            {bookingItems
              .filter(parentFilterItem => parentFilterItem.isIncluded)
              .map(
                (parentMapItem, parentMapIndex) =>
                  checkIncludedProductQuantity(parentMapItem) && (
                    <div className="review-step__row" key={parentMapIndex}>
                      <p className="row-label">
                        {getFilteredBookingItems(parentMapItem).length > 1 &&
                          `${getFilteredBookingItems(parentMapItem).length}x `}
                        {parentMapItem.parentName}
                      </p>
                      <p className="row-value">Included</p>
                      {parentMapItem.index !== undefined &&
                        getFilteredBookingItems(parentMapItem).map(
                          (childMapItem, childMapIndex) => (
                            <div className="review-step__sub-row" key={childMapIndex}>
                              <p className="subrow-label">{childMapItem.name}</p>
                              <p className="subrow-value"></p>
                            </div>
                          )
                        )}
                    </div>
                  )
              )}
          </div>
        </div>
        {bookingItems.filter(parentFilterItem => !parentFilterItem.isIncluded).length > 0 && (
          <>
            <div className="review-step__order-item">
              <h4 className="order-item-label">Add-ons</h4>
              <div className="review-step__rows">
                {bookingItems
                  .filter(parentFilterItem => !parentFilterItem.isIncluded)
                  .map(
                    (parentMapItem, parentMapIndex) =>
                      checkOptionalProductQuantity(parentMapItem) && (
                        <div className="review-step__row" key={parentMapIndex}>
                          <p className="row-label">{parentMapItem.parentName}</p>
                          <p className="row-value"></p>
                          {getFilteredBookingItems(parentMapItem).map(
                            (childMapItem, childMapIndex) => (
                              <div className="review-step__sub-row" key={childMapIndex}>
                                <p className="subrow-label">{childMapItem.name}</p>
                                <p className="subrow-value">
                                  {childMapItem.quantity > 1 && `${childMapItem.quantity} x `}$
                                  {childMapItem.cost.toFixed(2)}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      )
                  )}
              </div>
            </div>

            <div className="review-step__addon-subtotal">
              <h5 className="addon-subtotal-label">Total cost for add-ons</h5>
              <h5 className="addon-subtotal-value">${getAddonsPrice()}</h5>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ReviewTable;
