import React, { useState, useEffect, useContext } from 'react';

import { DataContext } from '../contexts/data';
import type { DataContextValueType } from '../contexts/data';

import { ModalContext } from '../contexts/modal';
import type { ModalContextValueType } from '../contexts/modal';

import { StepsContext } from '../contexts/steps';
import type { StepsContextValueType } from '../contexts/steps';

import TimeOverAlert from './Alerts/TimeOver';
import ErrorAlert from './Alerts/Error';

import API from '../api/api';

function SubHeader() {
  const countDownStartValue = 10; // minutes
  const [remainingTime, setRemainingTime] = useState('00:00');
  const [interval, setIntervalVar] = useState<ReturnType<typeof setTimeout> | undefined>();

  const {
    bookingData: [bookingData, setBookingData],
    bookingItems: [bookingItems],
    selectedPackage,
    isEditMode
  } = useContext(DataContext) as DataContextValueType;

  const {
    steps: [stepsData]
  } = useContext(StepsContext) as StepsContextValueType;

  const {
    showLoading,
    hideLoading,
    open: openModal,
    close: closeModal
  } = useContext(ModalContext) as ModalContextValueType;

  const handleTimeOver = () => {
    const closeOnClickOutside = false;
    openModal(<TimeOverAlert onCloseModal={closeModal} />, closeOnClickOutside);
  };

  const formatNumber = (number: number) => {
    if (`${number}`.length === 1) {
      return `0${number}`;
    }

    return `${number}`;
  };

  const countdown = () => {
    if (interval) clearInterval(interval);

    const countDownDate = new Date().getTime() + countDownStartValue * 60 * 1000 + 2000;

    setIntervalVar(
      setInterval(() => {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setRemainingTime(`${formatNumber(minutes)}:${formatNumber(seconds)}`);

        if (
          ((minutes === 0 && seconds === 0) || seconds < 0) &&
          window.location.pathname !== '/confirmation' &&
          !stepsData.find(step => step.value === 'checkout')?.done
        ) {
          setRemainingTime(`00:00`);
          clearInterval(interval);
          handleTimeOver();
        }
      }, 1000)
    );
  };

  const getPackageItem = () => {
    return {
      productId: selectedPackage?.products[0].id || '',
      quantity: bookingData.extra.jumpersNumber,
      bookingDate: bookingData.extra.bookingDate,
      startTime: bookingData.extra.startTime,
      name: selectedPackage?.products[0].name || '',
      parentName: selectedPackage?.name || '',
      cost: selectedPackage?.products[0].cost || 0,
      tax: selectedPackage?.products[0].tax || 0,
      parentId: selectedPackage?.id || '',
      partyPackageInclusions: bookingItems.filter(bookingItem => bookingItem.isIncluded)
    };
  };

  const getBookingPayload = () => {
    const packageItem = getPackageItem();
    const filteredBookingItems = bookingItems.filter(bookingItem => !bookingItem.isIncluded);
    const items = [...filteredBookingItems, packageItem];
    const payload = {
      items
    };
    return payload;
  };

  const startSession = async () => {
    showLoading();

    try {
      const capacityReservationPayload = getBookingPayload();
      if (bookingData.extra.capacityReservationId) {
        await API.deleteCapacityReservation(bookingData.extra.capacityReservationId);
      }
      const capacityReservationRes = await API.capacityReservation(capacityReservationPayload);

      if (capacityReservationRes.errors) {
        openModal(
          <ErrorAlert
            title="Session is not available"
            description={capacityReservationRes.errors[0].message}
            onCloseModal={closeModal}
            buttons={['exitBooking', 'reload']}
          />,
          false
        );
      }

      setBookingData({
        ...bookingData,
        extra: {
          ...bookingData.extra,
          capacityReservationId: capacityReservationRes.uniqueId
        }
      });
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    !isEditMode && startSession();
  }, [bookingData.extra.bookingDate, bookingData.extra.startTime]);

  useEffect(() => {
    if (!bookingData.extra.capacityReservationId) return;
    countdown();
  }, [bookingData.extra.capacityReservationId]);

  return (
    <header className="subheader">
      <div className="subheader__content">
        <div className="subheader__content__container">
          <span className="subheader__content__time">Time Remaining: {remainingTime}</span>
        </div>
      </div>
    </header>
  );
}

export default SubHeader;
