import React from "react";

import BasicLayout from './layouts/Basic';
import ReviewLayout from './layouts/Review';

import StepsPage from './pages/Steps';
import ConfirmationPage from './pages/Confirmation';
import ReviewPage from './pages/Review';

import {
  createBrowserRouter,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <BasicLayout />,
    children: [
      {
        path: "/",
        element: <StepsPage />,
      },
    ],
  },
  {
    path: "/confirmation",
    element: <BasicLayout />,
    children: [
      {
        path: "/confirmation",
        element: <ConfirmationPage />,
      },
    ],
  },
  {
    path: "/review",
    element: <ReviewLayout />,
    children: [
      {
        path: "/review",
        element: <ReviewPage />,
      },
    ],
  },
]);

export default router;