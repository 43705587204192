import React from "react";

import type { ReactNode } from "react";

import classnames from "classnames";

type ButtonProps = {
  children: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  onClick?: () => void;
  kind?: 'primary' | 'secondary' | 'ghost' | 'outline';
  type?: 'button' | 'submit' | 'reset';
  form?: string;
  disabled?: boolean;
  loading?: boolean;
};

function Button({ children, onClick, kind = "primary", leftIcon, rightIcon, type = 'button', form, disabled, loading }: ButtonProps) {
  return (
    <button className={classnames({
      "btn": true,
      [`btn--${kind}`]: kind,
    })}
    type={type}
    form={form}
    disabled={disabled}
    onClick={onClick}>
      {loading && <div className="btn__loading-spinner" />}
      {leftIcon && <span className="btn__icon btn__icon--left">{leftIcon}</span>}
      <span className="btn__text">{children}</span>
      {rightIcon && <span className="btn__icon btn__icon--right">{rightIcon}</span>}
    </button>
  );
}


export default Button;
