export const formatPhone = (value: string) => {
  const arrValue = value.split('');
  let formatedValue = '';

  for (let key of arrValue) {
    if (key.match(/[^0-9]/g)) {
      key = '';
    }

    if (formatedValue.length === 0) {
      formatedValue = `${formatedValue}(`;
    } else if (formatedValue.length === 4) {
      formatedValue = `${formatedValue})-`;
    } else if (formatedValue.length === 9) {
      formatedValue = `${formatedValue}-`;
    } else if (formatedValue.length >= 14) {
      key = '';
    }

    formatedValue += key;
  }

  return formatedValue;
};

export const removeEmojis = (value: string) => {
  const cleanValue = value.replace(
    /(?:[:;=]-?[)(DP/])|(?:<[/\\]?3)|(?:\([oO]\))|([^\w\s.,!?@#$%^&*()_+\-=[\]{};':"\\|,.<>/~`´])/g,
    ''
  );
  return cleanValue.trim();
};
