import React, { useContext, useEffect, useRef } from 'react';
import type { RefObject } from 'react';
import classNames from 'classnames';
import { useForm, Controller } from 'react-hook-form';
import { DataContext } from '../../../contexts/data';
import type { DataContextValueType } from '../../../contexts/data';
import FormField from '../../../components/FormField';
import Button from '../../../components/Button';

export type FormValues = {
  email: string;
  guestOfHonorFirstName: string;
  guestOfHonorDob: string;
  secondGuestOfHonorFirstName?: string;
  secondGuestOfHonorDob?: string;
  thirdGuestOfHonorFirstName?: string;
  thirdGuestOfHonorDob?: string;
};

type GuestDetailsFormProps = {
  formRef: RefObject<HTMLFormElement>;
  setNextDisabled: (value: boolean) => void;
  onSubmit: (data: FormValues) => void;
  guestOfHonorCount: number;
  addGuestOfHonor: () => void;
  removeGuestOfHonor: () => void;
};

const GuestDetailsForm = ({
  formRef,
  setNextDisabled,
  onSubmit,
  guestOfHonorCount,
  addGuestOfHonor,
  removeGuestOfHonor
}: GuestDetailsFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    trigger,
    unregister
  } = useForm<FormValues>({ mode: 'onBlur' });

  const {
    bookingData: [bookingData],
    bookingDetails: [bookingDetails],
    isEditMode
  } = useContext(DataContext) as DataContextValueType;

  const firstLoad = useRef(true);

  const buttonText =
    guestOfHonorCount === 1 ? 'Add a Second Guest of Honor' : 'Add a Third Guest of Honor';

  const validateName = (value: string) => {
    const emojiRegex = /^[\p{Emoji}\s]+$/u;
    const validNameRegex =
      /^(?=.*[a-zA-Z0-9\s\u00C0-\u024F\u1E00-\u1EFF\p{P}\-~'´`])[\p{L}\p{N}\p{P}\p{Emoji}\s\-~'´`]+$/u;

    if (emojiRegex.test(value)) {
      return 'Please enter a valid name';
    }
    if (!validNameRegex.test(value)) {
      return 'Please enter a valid name';
    }
    return true;
  };

  useEffect(() => {
    const subscription = watch(async data => {
      let emptyFields = Object.entries(data).filter(([_, value]) => !value);
      if (guestOfHonorCount === 1) {
        emptyFields = emptyFields.filter(
          ([key, _]) => key !== 'secondGuestOfHonorFirstName' && key !== 'secondGuestOfHonorDob'
        );
      } else if (guestOfHonorCount === 2) {
        emptyFields = emptyFields.filter(
          ([key, _]) => key !== 'thirdGuestOfHonorFirstName' && key !== 'thirdGuestOfHonorDob'
        );
      }
      if (emptyFields.length) {
        if (isEditMode && emptyFields.length === 1 && emptyFields[0][0] === 'email') {
          setNextDisabled(false);
          return;
        }
        setNextDisabled(true);
        return;
      }
      setNextDisabled(false);
    });
    return () => subscription.unsubscribe();
  }, [watch, isEditMode, setNextDisabled, guestOfHonorCount]);

  useEffect(() => {
    if (guestOfHonorCount === 2) {
      setValue(
        'secondGuestOfHonorFirstName',
        formRef.current?.secondGuestOfHonorFirstName?.value || ''
      );
      setValue('secondGuestOfHonorDob', formRef.current?.secondGuestOfHonorDob?.value || '');
      trigger();
    } else if (guestOfHonorCount === 3) {
      setValue(
        'thirdGuestOfHonorFirstName',
        formRef.current?.thirdGuestOfHonorFirstName?.value || ''
      );
      setValue('thirdGuestOfHonorDob', formRef.current?.thirdGuestOfHonorDob?.value || '');
      trigger();
    }

    if (guestOfHonorCount === 1 && !firstLoad.current) {
      unregister(['secondGuestOfHonorFirstName', 'secondGuestOfHonorDob']);
      unregister(['thirdGuestOfHonorFirstName', 'thirdGuestOfHonorDob']);
      trigger();
    }
    firstLoad.current = false;
  }, [guestOfHonorCount]);

  return (
    <div className="guest-details__form-container">
      <form
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        className="guest-details__form"
        noValidate
        id="enter-guest-details"
      >
        <Controller
          name="email"
          control={control}
          defaultValue={bookingDetails?.customerInfo.email}
          rules={
            isEditMode
              ? {}
              : {
                  required: 'Please enter a valid email address.',
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email address.'
                  }
                }
          }
          render={({ field }) => (
            <div className="guest-details-row--full-width">
              <FormField
                type="email"
                label="Your Email Address*"
                error={errors.email?.message}
                {...field}
              />
            </div>
          )}
        />
        <Controller
          name="guestOfHonorFirstName"
          defaultValue={bookingData.extra.guestOfHonorFirstName}
          control={control}
          rules={{
            required: "Please enter guest of honor's first name.",
            validate: (value: string | undefined) => validateName(value || '')
          }}
          render={({ field }) => (
            <FormField
              type="text"
              label="Guest of Honor's First Name*"
              error={errors.guestOfHonorFirstName?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="guestOfHonorDob"
          defaultValue={bookingData.extra.guestOfHonorDob}
          control={control}
          rules={{
            required: 'Please enter valid date of birth.',
            maxLength: {
              value: 10,
              message: 'Please enter a valid date of birth.'
            }
          }}
          render={({ field }) => (
            <FormField
              type="date"
              label="Guest of Honor's Date of Birth*"
              error={errors.guestOfHonorDob?.message}
              {...field}
            />
          )}
        />
        {guestOfHonorCount > 1 && (
          <>
            <Controller
              name="secondGuestOfHonorFirstName"
              defaultValue={bookingData.extra.secondGuestOfHonorFirstName}
              control={control}
              rules={{
                required: "Please enter guest of honor's first name.",
                validate: (value: string | undefined) => validateName(value || '')
              }}
              render={({ field }) => (
                <FormField
                  type="text"
                  label="Additional Guest of Honor's First Name"
                  error={errors.secondGuestOfHonorFirstName?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="secondGuestOfHonorDob"
              defaultValue={bookingData.extra.secondGuestOfHonorDob}
              control={control}
              rules={{
                required: 'Please enter valid date of birth.',
                maxLength: {
                  value: 10,
                  message: 'Please enter a valid date of birth.'
                }
              }}
              render={({ field }) => (
                <FormField
                  type="date"
                  label="Additional Guest of Honor's Date of Birth"
                  error={errors.secondGuestOfHonorDob?.message}
                  {...field}
                />
              )}
            />
          </>
        )}
        {guestOfHonorCount > 2 && (
          <>
            <Controller
              name="thirdGuestOfHonorFirstName"
              defaultValue={bookingData.extra.thirdGuestOfHonorFirstName}
              control={control}
              rules={{
                required: "Please enter guest of honor's first name.",
                validate: (value: string | undefined) => validateName(value || '')
              }}
              render={({ field }) => (
                <FormField
                  type="text"
                  label="Additional Guest of Honor's First Name"
                  error={errors.thirdGuestOfHonorFirstName?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name="thirdGuestOfHonorDob"
              defaultValue={bookingData.extra.thirdGuestOfHonorDob}
              control={control}
              rules={{
                required: 'Please enter valid date of birth.',
                maxLength: {
                  value: 10,
                  message: 'Please enter a valid date of birth.'
                }
              }}
              render={({ field }) => (
                <FormField
                  type="date"
                  label="Additional Guest of Honor's Date of Birth"
                  error={errors.thirdGuestOfHonorDob?.message}
                  {...field}
                />
              )}
            />
          </>
        )}
        {guestOfHonorCount <= 2 && (
          <div
            className={classNames('guest-details__button-container', {
              'guest-details__button-container--full-width':
                guestOfHonorCount === 1 || guestOfHonorCount === 3
            })}
          >
            <Button kind="outline" onClick={addGuestOfHonor}>
              {buttonText}
            </Button>
          </div>
        )}
        {guestOfHonorCount > 1 && (
          <div
            className={classNames('guest-details__button-container', {
              'guest-details__button-container--full-width':
                guestOfHonorCount === 1 || guestOfHonorCount === 3
            })}
          >
            <Button kind="outline" onClick={removeGuestOfHonor}>
              Remove last guest of honor
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default GuestDetailsForm;
