import React, { useContext } from 'react';

import { DataContext } from "../../../contexts/data";
import type { DataContextValueType } from "../../../contexts/data";

const DateAndTimeDescription = () => {
  const {
    partyNameEl,
  } = useContext(DataContext) as DataContextValueType;

  return (
    <div className="container-sm step-container__subheader">
      <p className="step-container__description"><span>Choose a date and time for {partyNameEl}</span></p>
    </div>
  );
};

export default DateAndTimeDescription;
