import React, { useContext } from "react";
import classnames from "classnames";

import { StepsContext } from "../contexts/steps";
import type { StepsContextValueType, Step, StepPosition } from "../contexts/steps";

type StepContainerProps = {
  children: React.ReactNode;
  index: number;
  step: Step;
};

function StepContainer({
  children,
  index,
  step,
}: StepContainerProps) {

  const {
    steps: [stepsData],
  } = useContext(StepsContext) as StepsContextValueType;

  const checkPosition = (): StepPosition => {
    const stepIndex = stepsData.findIndex((stepData) => stepData.value === step.value);
    const activeIndex = stepsData.findIndex((stepData) => stepData.active);

    if (stepIndex === activeIndex) {
      return 'active';
    }

    if (stepIndex < activeIndex) {
      return 'prev';
    }

    return 'next';
  }

  return (
    <section className={classnames({
      "step-container": true,
      "step-container--active": checkPosition() === 'active',
      "step-container--prev": checkPosition() === 'prev',
      "step-container--next": checkPosition() === 'next',
    })}
    aria-hidden={checkPosition() !== 'active'}
    >
      <div className="step-container__content">
        <div className="container-sm step-container__header">
          <span className="step-container__counter">{`Step ${index+1} of ${stepsData.length}`}</span>
          <h1 className="step-container__heading">{step.heading}</h1>
        </div>
        {children}
      </div>
    </section>
  );
}


export default StepContainer;
