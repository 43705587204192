import React, { useState } from 'react';
import Counter from '../../../components/Counter';

import type { SubProduct, BookingItem } from "../../../contexts/data";

type SubItemRowProps = {
  subProduct: SubProduct;
  onSubProductChange: (quantity: number, subProduct: SubProduct) => void;
  selectedSubProduct?: BookingItem;
};

const SubItemRow = ({ subProduct, onSubProductChange, selectedSubProduct }: SubItemRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCounterChange = (count: number) => {
    onSubProductChange(count, subProduct);
  }

  const getPricesSum = () => {
    if (!selectedSubProduct) return subProduct.cost.toFixed(2);
    return (selectedSubProduct.quantity * selectedSubProduct.cost).toFixed(2);
  }

  return (
    <div>
      <div className="party-item-row__detail">
        <div className="text-container">
          <p className='party-item-row__sub-item-title'>{subProduct.name}</p>
          <h6 className='party-item-row__sub-item-price'>${getPricesSum()}</h6>
          {subProduct.description && <button className='party-item-row__sub-item-toggle-visibility' onClick={toggleExpand}>{isExpanded ? 'Hide Details' : 'View Details'}</button>}
        </div>
        <Counter value={(selectedSubProduct && selectedSubProduct.quantity) ? selectedSubProduct.quantity : 0} minValue={0} onInput={handleCounterChange} onChange={handleCounterChange} />
      </div>
      {isExpanded && subProduct.description && <p className='party-item-row__sub-item-description'>{subProduct.description}</p>}
    </div>
  );
};

export default SubItemRow;
