import React from 'react';

type RadioButtonProps = {
  label: string;
  name: string;
  value: string;
  checked: boolean;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
};

function RadioButton({
  label,
  name,
  value,
  onChange,
  checked,
  ...field
}: RadioButtonProps) {

  return (
    <div className="radio-button">
      <input
        className="radio-button__input"
        type="radio"
        value={value}
        name={name}
        onChange={onChange}
        {...field}
        checked={checked}
      />

      <label className="radio-button__label">
        {label}
      </label>
    </div>
  );
}

export default RadioButton;
