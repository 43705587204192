import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { StepsContext } from '../contexts/steps';
import type { StepsContextValueType } from '../contexts/steps';

import StepContainer from '../components/StepContainer';

import ProgressBar from '../modules/ProgressBar';
import GuestDetails from '../modules/steps/GuestDetails';
import DateAndTime from '../modules/steps/DateAndTime';
import AddOns from '../modules/steps/AddOns';
import Review from '../modules/steps/Review';
import Checkout from '../modules/steps/Checkout';
import SubHeader from '../components/SubHeader';

import { DataContext } from '../contexts/data';
import type { DataContextValueType } from '../contexts/data';

function StepsPage() {
  const {
    steps: [stepsData],
    goToNextStep,
    goToStep
  } = useContext(StepsContext) as StepsContextValueType;

  const { isEditMode } = useContext(DataContext) as DataContextValueType;

  const navigate = useNavigate();

  const stepsEl = [
    <h1>STEP 1</h1>,
    <h1>STEP 2</h1>,
    <GuestDetails
      onNext={(stepValue?: string | null) => (stepValue ? goToStep(stepValue) : goToNextStep())}
    />,
    <DateAndTime onNext={goToNextStep} />,
    <AddOns onNext={goToNextStep} />,
    <Review onNext={goToNextStep} />,
    <Checkout
      onNext={() => {
        goToNextStep();
        navigate('/confirmation');
      }}
    />
  ];

  return (
    <>
      {stepsData[3].done && !stepsData.find(step => step.value === 'checkout')?.done && (
        <SubHeader />
      )}
      <ProgressBar />

      <div className="step-container__wrapper">
        {stepsData.map((step, index) => (
          <StepContainer key={step.value} index={index} step={step}>
            {isEditMode && step.value === 'review-order' ? null : stepsEl[index]}
          </StepContainer>
        ))}
      </div>
    </>
  );
}

export default StepsPage;
