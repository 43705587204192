import React, { useContext } from 'react';
import type { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import Modal from '../components/Modal';

import { ModalContext } from '../contexts/modal';
import type { ModalContextValueType } from '../contexts/modal';

function ReviewLayout() {
  const {
    isShowing: isModalShowing,
    isLoadingShowing,
    close: closeModal,
    content: modalConent,
    closeOnClickOutside
  } = useContext(ModalContext) as ModalContextValueType;

  return (
    <div className="app has-subheader has-steps">
      <Header />
      <main>
        <Outlet />
      </main>
      <Footer />
      <Modal
        isShowing={isModalShowing}
        content={modalConent as ReactElement}
        onCloseModal={closeModal}
        closeOnClickOutside={closeOnClickOutside}
      />
      <Loading isShowing={isLoadingShowing} />
    </div>
  );
}

export default ReviewLayout;
