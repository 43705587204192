import React, { useContext } from 'react';

import { DataContext } from '../../../contexts/data';
import type { DataContextValueType } from '../../../contexts/data';

import Button from '../../../components/Button';
import Svg from '../../../components/Svg';

type GuestDetailsButtonsProps = {
  handlePrev: () => void;
  nextDisabled: boolean;
  isOverVenueLimit: boolean;
  isDecresingJumpers?: boolean;
};

const GuestDetailsButtons = ({
  handlePrev,
  nextDisabled,
  isOverVenueLimit,
  isDecresingJumpers
}: GuestDetailsButtonsProps) => {
  const { venueInfo, isEditMode } = useContext(DataContext) as DataContextValueType;

  return (
    <div className="step-container__buttons container">
      {!isEditMode && (
        <Button onClick={handlePrev} kind="ghost" leftIcon={<Svg content="arrow-left" />}>
          Back
        </Button>
      )}

      <Button
        type="submit"
        form="enter-guest-details"
        disabled={nextDisabled || !venueInfo.name || isOverVenueLimit}
        loading={!venueInfo.name}
        rightIcon={<Svg content="double-chevron-right" />}
      >
        {isDecresingJumpers ? 'Continue to Add ons' : 'Next: Select date & time'}
      </Button>
    </div>
  );
};

export default GuestDetailsButtons;
