import React from 'react';

type AddonsPricingProps = {
  packageName?: string;
  packagePrice: number;
  addonsPrice: number;
  totalPrice: number;
};

const AddonsPricing = ({ packageName, packagePrice, addonsPrice, totalPrice }: AddonsPricingProps) => {
  return (
    <div className="add-ons__total-wrapper">
      <div className="add-ons__sub-total container-sm">
        <div className="add-ons__total-row">
          <p className="total-row__total-label">{packageName}</p>
          <p className="total-row__total-value">${packagePrice.toFixed(2)}</p>
        </div>
        <div className="add-ons__total-row ">
          <p className="total-row__total-label">Add-ons</p>
          <p className="total-row__total-value">${addonsPrice.toFixed(2)}</p>
        </div>
      </div>
      <div className="add-ons__total container-sm">
        <p className="total-label">Subtotal</p>
        <p className="total-value">${totalPrice.toFixed(2)}</p>
      </div>
    </div>
  );
};

export default AddonsPricing;
