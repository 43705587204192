import React from 'react';

const GuestDetailsDescription = () => {
  return (
    <div className="container-sm step-container__subheader">
      <p className="step-container__description"><span>Tell us a little bit more about your party.</span></p>
    </div>
  );
};

export default GuestDetailsDescription;
