import React, { useContext } from 'react';

import { DataContext } from "../../../contexts/data";
import type { DataContextValueType } from "../../../contexts/data";

import Button from '../../../components/Button';
import Svg from '../../../components/Svg';

type CheckoutButtonsProps = {
  nextDisabled: boolean;
};

const CheckoutButtons = ({ nextDisabled }: CheckoutButtonsProps) => {
  const {
    bookingData: [bookingData],
    paymentReady: [paymentReady],
  } = useContext(DataContext) as DataContextValueType;

  return (
    <div className="checkout__footer">
      <div className="checkout__footer__buttons">
        {!paymentReady && (
          <Button type="submit" disabled={nextDisabled} rightIcon={<Svg content="double-chevron-right" />}>
            {bookingData.extra.prices?.total === 0 ? 'CREATE BOOKING' : 'CONTINUE TO PAYMENT'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default CheckoutButtons;
