import React, { useContext, useState, useEffect } from 'react';
import type { ReactElement } from 'react';

import { DataContext } from '../../../contexts/data';
import type {
  DataContextValueType,
  Product,
  SubProduct,
  BookingItem
} from '../../../contexts/data';

import Dropdown from '../../../components/Dropdown';
import IconTooltip from '../../../components/IconTooltip';

import { includedAddonCategories } from '../../../constants/addOns';

type IndexableProduct = Product & {
  supportIndex: number;
  supportValue?: string;
  supportLabel?: string;
};

type IncludedAddonsProps = {
  includedAddons: Product[];
  optionalAddons: Product[];
  selectedSubProducts: BookingItem[];
  handleSubProductChange: (
    count: number,
    addon: Product,
    subProduct: SubProduct,
    index?: number,
    isIncluded?: boolean
  ) => void;
  handleSelectChange: (
    option: { label: string; value: string },
    addon: Product,
    index: number
  ) => void;
  isFormSubmited: boolean;
};

const IncludedAddons = ({
  includedAddons,
  optionalAddons,
  selectedSubProducts,
  handleSelectChange,
  handleSubProductChange,
  isFormSubmited
}: IncludedAddonsProps) => {
  const {
    bookingData: [bookingData],
    isEditMode
  } = useContext(DataContext) as DataContextValueType;

  const [additionalDropdowns, setAdditionalDropdowns] = useState<IndexableProduct[]>([]);

  const getIncludedAddonIcon = (addon: Product): ReactElement => {
    const addonName = addon.name;
    if (includedAddonCategories.food.includes(addonName)) {
      return <img src="/icons/pizza.svg" alt="Pizza" width={40} height={40} />;
    }

    if (includedAddonCategories.drinks.includes(addonName)) {
      return <img src="/icons/drink.svg" alt="Drink" width={40} height={40} />;
    }

    return <img src="/icons/balloons.svg" alt="Balloons" width={40} height={40} />;
  };

  const getQuantity = (addon: Product) => {
    if (!addon.quantityPerGuest) return 1;

    if (addon.quantityType === 'package') return addon.quantityPerGuest || 1;
    if (addon.packageRequirement === 'after')
      return Math.floor(bookingData.extra.jumpersNumber / addon.quantityPerGuest);
    if (addon.packageRequirement === 'before')
      return Math.ceil(bookingData.extra.jumpersNumber / addon.quantityPerGuest);

    return Math.floor(bookingData.extra.jumpersNumber / addon.quantityPerGuest);
  };

  const getDropdownOptionValue = (addon: Product, index: number) => {
    const subProduct = selectedSubProducts.find(
      item => item.parentId === addon.id && item.index === index
    );

    if (subProduct) {
      return { label: subProduct.name, value: subProduct.productId };
    }

    return { label: 'Select', value: '' };
  };

  const getAdditionalDropdownOptionValue = (addon: IndexableProduct) => {
    if (addon.supportLabel && addon.supportValue) {
      return { label: addon.supportLabel, value: addon.supportValue };
    }

    return { label: 'Select', value: '' };
  };

  const onAdditionalDropdownChange = (
    option: { label: string; value: string },
    additionalItem: IndexableProduct
  ) => {
    const previousSubProduct = additionalItem.products.find(
      product => product.id === additionalItem.supportValue
    );

    if (previousSubProduct) {
      const previousQuantity = selectedSubProducts.find(
        selectedSubProduct => selectedSubProduct.productId === previousSubProduct.id
      )?.quantity;
      handleSubProductChange(
        previousQuantity ? previousQuantity - 1 : 0,
        additionalItem,
        previousSubProduct
      );
    }

    const subProduct = additionalItem.products.find(product => product.id === option.value);
    if (!subProduct) return;
    const currentSubproduct = selectedSubProducts.find(
      selectedSubProduct => subProduct.id === selectedSubProduct.productId
    );

    const newQuantity = currentSubproduct ? currentSubproduct.quantity + 1 : 1;

    setAdditionalDropdowns((prevAdditionalDropdowns: IndexableProduct[]) => {
      const dropdownIndex = prevAdditionalDropdowns.findIndex(
        dropdown =>
          dropdown.id === additionalItem.id && dropdown.supportIndex === additionalItem.supportIndex
      );
      const updatedAdditionalDropdowns = [...prevAdditionalDropdowns];
      updatedAdditionalDropdowns.splice(dropdownIndex, 1, {
        ...additionalItem,
        supportLabel: `${subProduct.name} (+$${subProduct.cost})`,
        supportValue: subProduct.id
      });

      return updatedAdditionalDropdowns;
    });

    setTimeout(() => {
      handleSubProductChange(newQuantity, additionalItem, subProduct);
    }, 100);
  };

  const getAddonShortName = (name: string) => {
    if (name.includes('Included Party Items - ')) {
      return name.replace('Included Party Items - ', '');
    }

    return 'Item';
  };

  const handleAddAddionalAddon = (addon: Product) => {
    const addonShortName = getAddonShortName(addon.name);
    if (addonShortName === 'Item') return;
    const optionalAddon = optionalAddons.find(optionalAddon =>
      optionalAddon.name.includes(addonShortName)
    );
    if (!optionalAddon) return;

    const supportIndex = additionalDropdowns.filter(
      additionaItem => additionaItem.id === optionalAddon.id
    ).length;

    const indexableAddon = {
      ...optionalAddon,
      supportIndex
    };

    setAdditionalDropdowns([...additionalDropdowns, indexableAddon]);
  };

  const handleRemoveAdditionalAddon = (addon: IndexableProduct) => {
    setAdditionalDropdowns((prevAdditionalDropdowns: IndexableProduct[]) => {
      const newItems = prevAdditionalDropdowns.filter(additionalItem => {
        if (additionalItem.id === addon.id && additionalItem.supportIndex === addon.supportIndex) {
          return false;
        }

        return true;
      });

      return newItems;
    });

    const subProduct = addon.products.find(product => product.id === addon.supportValue);

    if (subProduct) {
      const previousQuantity = selectedSubProducts.find(
        selectedSubProduct => selectedSubProduct.productId === subProduct.id
      )?.quantity;
      handleSubProductChange(previousQuantity ? previousQuantity - 1 : 0, addon, subProduct);
    }
  };

  const fillAdditionalDropdowns = () => {
    const editableDropdowns: IndexableProduct[] = [];

    selectedSubProducts.forEach(selectedSubProduct => {
      const addon = optionalAddons.find(
        optionalAddon => optionalAddon.id === selectedSubProduct.parentId
      );

      if (!addon) return;

      const isInIncludedAddons = includedAddons.find(includedAddon => {
        return addon.name.includes(getAddonShortName(includedAddon.name));
      });

      if (!isInIncludedAddons) return;

      const supportIndex = editableDropdowns.filter(
        additionaItem => additionaItem.id === addon.id
      ).length;

      const indexableAddon = {
        ...addon,
        supportIndex,
        supportLabel: `${selectedSubProduct.name} (+$${selectedSubProduct.cost})`,
        supportValue: selectedSubProduct.productId
      };

      Array.from({ length: selectedSubProduct.quantity }).forEach((_, i) => {
        editableDropdowns.push({
          ...indexableAddon,
          supportIndex: supportIndex + i
        });
      });
    });

    setAdditionalDropdowns(editableDropdowns);
  };

  const getTooltipText = (name: string) => {
    const shortName = getAddonShortName(name);
    if (shortName === 'Pizza') {
      return 'Epic and Mega VIP packages include 1 large pizza for every 5 jumpers. Option to add additional pizzas for extra hungry kids, and parents too!';
    }

    if (shortName === 'Drinks') {
      return 'Epic and Mega VIP packages include one drink pitcher for every 5 jumpers. Add-on additional pitchers for extra cheers!';
    }

    if (shortName === 'T-Shirt') {
      return 'Epic and Mega VIP packages include 1 Sky Zone Party T-shirt for the Guest of Honor. Celebrating more than 1 special Guest? Additional Party T-shirts can be added-on.';
    }

    return '';
  };

  const checkIfAddAdditionalAddonButtonShouldBeDisabled = (addon: Product) => {
    const additionaDropdownsForAddon = additionalDropdowns.filter(additionalItem =>
      additionalItem.name.includes(getAddonShortName(addon.name))
    );
    const additionalDropdownsForAddonWithSupportValue = additionaDropdownsForAddon.filter(
      additionalItem => additionalItem.supportValue
    );

    if (
      (additionalDropdownsForAddonWithSupportValue.length > 0 &&
        additionalDropdownsForAddonWithSupportValue.length === additionaDropdownsForAddon.length) ||
      additionaDropdownsForAddon.length === 0
    )
      return false;
    else {
      return true;
    }
  };

  useEffect(() => {
    if (isEditMode && optionalAddons.length > 0) {
      fillAdditionalDropdowns();
    }
  }, [optionalAddons]);

  return includedAddons.filter(addon => addon.products.length > 1).length > 0 ? (
    <div className="container-sm included-items__wrapper">
      <h3 className="included-items__title">Included with your package</h3>

      {includedAddons.map(addon => (
        <div key={addon.id} className="included-items__row">
          <div className="included-items__row-title">
            {getIncludedAddonIcon(addon)}
            <h3>{addon.name}</h3>
            {getTooltipText(addon.name) && <IconTooltip text={getTooltipText(addon.name)} />}
          </div>
          <div className="included-items__dropdown-wrapper">
            {addon.quantityPerGuest &&
              new Array(getQuantity(addon)).fill('').map((_, index) => (
                <Dropdown
                  key={`addon-${addon.id}-${index + 1}`}
                  id={`addon-${addon.id}-${index + 1}`}
                  label={`${addon.name} ${index + 1}`}
                  value={getDropdownOptionValue(addon, index)}
                  options={addon.products.map(product => ({
                    label: product.name,
                    value: product.id
                  }))}
                  onChange={option => handleSelectChange(option, addon, index)}
                  error={
                    isFormSubmited &&
                    !selectedSubProducts.find(
                      item => item.parentId === addon.id && item.index === index
                    )
                      ? 'Please select an option'
                      : undefined
                  }
                />
              ))}

            {!addon.quantityPerGuest && (
              <Dropdown
                id={`addon-${addon.id}`}
                label={`${addon.name}`}
                value={getDropdownOptionValue(addon, 0)}
                options={addon.products.map(product => ({
                  label: product.name,
                  value: product.id
                }))}
                onChange={option => handleSelectChange(option, addon, 0)}
                error={
                  isFormSubmited &&
                  !selectedSubProducts.find(item => item.parentId === addon.id && item.index === 0)
                    ? 'Please select an option'
                    : undefined
                }
              />
            )}
          </div>

          <div className="included-items__addons-wrapper">
            {additionalDropdowns.filter(additionalItem =>
              additionalItem.name.includes(getAddonShortName(addon.name))
            ).length > 0 && (
              <div className="included-items__dropdown-wrapper">
                {additionalDropdowns
                  .filter(additionalItem =>
                    additionalItem.name.includes(getAddonShortName(addon.name))
                  )
                  .map((additionalItem, index) => (
                    <div
                      className="included-items__dropdown-container"
                      key={`additionalItem-${additionalItem.id}-${index + 1}`}
                    >
                      <Dropdown
                        id={`additionalItem-${additionalItem.id}-${index + 1}`}
                        label={`Optional Add-On - ${getAddonShortName(addon.name)} ${
                          getQuantity(addon) + index + 1
                        }`}
                        value={getAdditionalDropdownOptionValue(additionalItem)}
                        options={additionalItem.products.map(product => ({
                          label: `${product.name} (+$${product.cost})`,
                          value: product.id
                        }))}
                        onChange={option => onAdditionalDropdownChange(option, additionalItem)}
                      />
                      <button
                        className="included-items__remove-addon"
                        aria-label="Remove Addon"
                        onClick={() => handleRemoveAdditionalAddon(additionalItem)}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25" fill="none">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.2539 12.4613L4.43668 19.2786C3.95571 19.7595 4.03787 19.9956 4.23232 20.19C4.42678 20.3845 4.66282 20.4666 5.14378 19.9857L11.961 13.1684L19.0353 20.2428C19.5163 20.7237 19.7785 20.6677 19.9729 20.4732C20.1674 20.2788 20.2234 20.0166 19.7424 19.5357L12.6681 12.4613L19.733 5.39647C20.2139 4.9155 20.1673 4.64391 19.9729 4.44945C19.7784 4.255 19.5068 4.2084 19.0259 4.68936L11.961 11.7542L5.15323 4.94645C4.67227 4.46549 4.42679 4.53822 4.23234 4.73267C4.03789 4.92713 3.96516 5.1726 4.44612 5.65356L11.2539 12.4613Z"
                            fill="currentColor"
                            stroke="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
              </div>
            )}

            {optionalAddons.filter(optionalAddon =>
              optionalAddon.name.includes(getAddonShortName(addon.name))
            ).length > 0 && (
              <button
                className="included-items__add-more-button"
                disabled={checkIfAddAdditionalAddonButtonShouldBeDisabled(addon)}
                onClick={() => handleAddAddionalAddon(addon)}
              >
                + Add Additional {getAddonShortName(addon.name)}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  ) : null;
};

export default IncludedAddons;
