import React, { useState, useContext } from 'react';
import classnames from 'classnames';

import { DataContext } from '../../../contexts/data';
import type {
  DataContextValueType,
  Product,
  SubProduct,
  BookingItem
} from '../../../contexts/data';
import type { Step } from '../../../contexts/steps';

import PartyItemRow from './OptionalAddonRow';

type OptionalAddonsProps = {
  includedAddons: Product[];
  optionalAddons: Product[];
  selectedSubProducts: BookingItem[];
  handleSubProductChange: (
    count: number,
    addon: Product,
    subProduct: SubProduct,
    index?: number,
    isIncluded?: boolean
  ) => void;
  activeStep: Step;
};

type AddonType = 'food' | 'decoration' | 'extra';

const OptionalAddons = ({
  optionalAddons,
  includedAddons,
  selectedSubProducts,
  handleSubProductChange,
  activeStep
}: OptionalAddonsProps) => {
  const { foodProductData, decorationProductData, extraProductData } = useContext(
    DataContext
  ) as DataContextValueType;

  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const getOptionalAddonsIcon = (category: string) => {
    if (category === 'food') {
      return <img src="/icons/pizza.svg" alt="Pizza" width={40} height={40} />;
    }

    if (category === 'decoration') {
      return <img src="/icons/balloons.svg" alt="Balloons" width={40} height={40} />;
    }

    return <img src="/icons/party.svg" alt="Party" width={40} height={40} />;
  };

  const getAddonsByCategory = (addons: Product[], category: AddonType) => {
    const categorizedData = {
      food: foodProductData,
      decoration: decorationProductData,
      extra: extraProductData
    };

    const filteredAddons = addons.filter(addon => {
      return categorizedData[category].find(product => product.id === addon.id);
    });

    return filteredAddons;
  };

  const toggleFilter = (filter: string) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(prevSelectedFilters =>
        prevSelectedFilters.filter(selectedFilter => selectedFilter !== filter)
      );
    } else {
      setSelectedFilters(prevSelectedFilters => [...prevSelectedFilters, filter]);
    }
  };

  const filtersShouldRender = () => {
    let count = 0;
    const hasFoodLength = !!getAddonsByCategory(optionalAddons, 'food').length;
    const hasDecorationLength = !!getAddonsByCategory(optionalAddons, 'decoration').length;
    const hasExtraLength = !!getAddonsByCategory(optionalAddons, 'extra').length;

    if (hasFoodLength) count++;
    if (hasDecorationLength) count++;
    if (hasExtraLength) count++;

    if (count === 1) return false;

    return true;
  };

  const getAddonShortName = (name: string) => {
    if (name.includes('Included Party Items - ')) {
      return name.replace('Included Party Items - ', '');
    }

    return 'Item';
  };

  return (
    <div className="add-ons__wrapper">
      <div className="container-sm">
        <h3 className="add-ons__title">Optional Add-ons</h3>

        {filtersShouldRender() && (
          <div className="add-ons__toggles-wrapper">
            {!!getAddonsByCategory(optionalAddons, 'food').length && (
              <button
                className={classnames({
                  'add-ons__toggle': true,
                  'add-ons__toggle--active': selectedFilters.includes('food')
                })}
                onClick={() => toggleFilter('food')}
              >
                Food
              </button>
            )}

            {!!getAddonsByCategory(optionalAddons, 'decoration').length && (
              <button
                className={classnames({
                  'add-ons__toggle': true,
                  'add-ons__toggle--active': selectedFilters.includes('decoration')
                })}
                onClick={() => toggleFilter('decoration')}
              >
                Decoration
              </button>
            )}

            {!!getAddonsByCategory(optionalAddons, 'extra').length && (
              <button
                className={classnames({
                  'add-ons__toggle': true,
                  'add-ons__toggle--active': selectedFilters.includes('extra')
                })}
                onClick={() => toggleFilter('extra')}
              >
                Party Extras
              </button>
            )}
          </div>
        )}

        {(['food', 'decoration', 'extra'] as AddonType[]).map(
          category =>
            !!getAddonsByCategory(optionalAddons, category).length &&
            (!selectedFilters.length || selectedFilters.includes(category)) && (
              <div className="add-ons__party-item-wrapper" key={category}>
                <div className="add-ons__add-on-type">
                  {getOptionalAddonsIcon(category)}
                  <h3>{category === 'extra' ? 'Party Extras' : category}</h3>
                </div>
                {activeStep?.value === 'select-addons' &&
                  getAddonsByCategory(optionalAddons, category).map(addon => {
                    const isInIncludedAddons = includedAddons.find(includedAddon => {
                      return addon.name.includes(getAddonShortName(includedAddon.name));
                    });

                    if (isInIncludedAddons) return null;

                    return (
                      <PartyItemRow
                        key={addon.id}
                        addon={addon}
                        onSubProductChange={handleSubProductChange}
                        selectedSubProducts={selectedSubProducts}
                      />
                    );
                  })}
              </div>
            )
        )}
        <img
          className="add-ons__confetti-1"
          src="/images/confetti.png"
          width={161}
          height={180}
          alt="confetti"
        />
        <img
          className="add-ons__confetti-2"
          src="/images/confetti.png"
          width={161}
          height={180}
          alt="confetti"
        />
        <img
          className="add-ons__confetti-3"
          src="/images/confetti.png"
          width={161}
          height={180}
          alt="confetti"
        />
      </div>
    </div>
  );
};

export default OptionalAddons;
