/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect } from 'react';
import type { ChangeEvent, LegacyRef } from 'react';
import classnames from 'classnames';
import { Email } from '@smastrom/react-email-autocomplete';

import { formatPhone } from '../helpers/input';

type FormFieldProps = {
  type:
    | 'text'
    | 'email'
    | 'password'
    | 'number'
    | 'tel'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'month'
    | 'week'
    | 'url'
    | 'search'
    | 'textarea'
    | 'color';
  label: string;
  name: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  error?: string;
  max?: number;
  onChange?: (value: string) => void;
  onBlur?: () => void;
};

const FormField: React.FC<FormFieldProps> = React.forwardRef(
  ({ label, name, type, placeholder, disabled, error, max, onChange, onBlur, ...field }, ref) => {
    const [length, setLength] = useState(0);
    const [emailValue, setEmailValue] = useState<string>(field.value || '');

    useEffect(() => {
      const calendarIcon = document.querySelector('.form-field__calendar-icon');
      const handleCalendarClick = () => {
        const closestContainer = calendarIcon?.closest('.form-field__container');
        if (!closestContainer) return;

        const dateInput = closestContainer.querySelector('input') as HTMLInputElement;
        if (dateInput) {
          dateInput.focus();
        }
      };

      calendarIcon?.addEventListener('click', handleCalendarClick);

      return () => {
        calendarIcon?.removeEventListener('click', handleCalendarClick);
      };
    }, []);

    type NativeEvent = {
      inputType: string;
    };

    const onInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
      const formattedPhone =
        (ev.nativeEvent as unknown as NativeEvent).inputType !== 'deleteContentBackward'
          ? formatPhone(ev.target.value)
          : ev.target.value;
      const newValue = type === 'tel' ? formattedPhone : ev.target.value;

      onChange?.(newValue);
    };

    const handleEmailChange = (newValue: string) => {
      setEmailValue(newValue);
      onChange?.(newValue);
    };

    return (
      <div
        className={classnames({
          'form-field__container': true,
          'form-field__container--error': error,
          'form-field__container--disabled': disabled
        })}
      >
        <label className="form-field__label" htmlFor={name}>
          {label}
        </label>

        {type === 'textarea' && (
          <textarea
            className="form-field__input"
            name={name}
            placeholder={placeholder}
            ref={ref as LegacyRef<HTMLTextAreaElement> | undefined}
            onChange={ev => {
              setLength(ev.target.value.length);
              onChange?.(ev.target.value);
            }}
            onBlur={onBlur}
            disabled={disabled}
            {...field}
          />
        )}
        {type === 'email' && (
          <Email
            className="form-field__input-email"
            name={name}
            placeholder={placeholder}
            value={emailValue}
            refineList={['gmail.com', 'hotmail.com', 'yahoo.com', 'icloud.com']}
            baseList={['gmail.com', 'hotmail.com', 'yahoo.com', 'icloud.com']}
            onChange={handleEmailChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        )}
        {type !== 'email' && type !== 'textarea' && (
          <input
            className="form-field__input"
            type={type}
            name={name}
            placeholder={placeholder}
            ref={ref as LegacyRef<HTMLInputElement> | undefined}
            onChange={onInputChange}
            onBlur={onBlur}
            disabled={disabled}
            {...field}
          />
        )}

        {type === 'textarea' && max && (
          <span className="form-field__counter">
            {length}/{max}
          </span>
        )}

        {error && <span className="form-field__error">{error}</span>}

        {type === 'date' && <span className="form-field__calendar-icon"></span>}
      </div>
    );
  }
);

export default FormField;
