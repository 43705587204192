import React, { useState, useRef, useEffect, useContext } from 'react';
import classnames from 'classnames';

import { StepsContext } from '../contexts/steps';
import type { StepsContextValueType, Step } from '../contexts/steps';

import { DataContext } from '../contexts/data';
import type { DataContextValueType } from '../contexts/data';

function ProgressBar() {
  const {
    steps: [stepsData],
    beforeGoToStep
  } = useContext(StepsContext) as StepsContextValueType;

  const {
    isEditMode,
    isDecreasingJumpers: [isDecreasingJumpers],
    paymentReady: [paymentReady]
  } = useContext(DataContext) as DataContextValueType;

  const dropdownEl = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownScrollTop, setDropdownScrollTop] = useState(0);

  const handleStepClick = (step: Step) => {
    beforeGoToStep(step.value);
    setShowDropdown(false);
  };

  const handleDropdownClick = (index: number) => {
    setDropdownScrollTop(56 * index);
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    if (dropdownEl.current) {
      (dropdownEl.current as HTMLElement).scrollTop = dropdownScrollTop;
    }
  }, [showDropdown]);

  const getStepLabel = (step: Step) => {
    if (isDecreasingJumpers && step.value === 'select-addons') {
      return 'Review Add-ons';
    }
    return step.buttonLabel;
  };

  const getStepIcon = (step: Step, index: number) => {
    if (isDecreasingJumpers && step.value === 'select-addons') {
      return (
        <img
          className="progress-bar__step__icon"
          src="/icons/warning-circle.svg"
          width={24}
          height={24}
          alt="step done"
        />
      );
    }
    if (step.done && !step.active) {
      return (
        <img
          className="progress-bar__step__icon"
          src="/icons/check-circle.svg"
          width={24}
          height={24}
          alt="step done"
        />
      );
    } else {
      return <span className="progress-bar__step__number">{index + 1}</span>;
    }
  };

  return (
    <section className={classnames('progress-bar', { 'progress-bar--disabled': paymentReady })}>
      <div className="container">
        {stepsData.map(
          (step, index) =>
            step.active && (
              <button
                key={step.value}
                className="progress-bar__active-item"
                onClick={() => handleDropdownClick(index)}
              >
                <div className="progress-bar__active-item__heading-container">
                  <>
                    {getStepIcon(step, index)}
                    <span className="progress-bar__active-item__heading">{getStepLabel(step)}</span>
                  </>
                </div>
                <img
                  className="progress-bar__active-item__icon"
                  src="/icons/chevron-down.svg"
                  alt="arrow down"
                  width={24}
                  height={24}
                />
              </button>
            )
        )}
        <div
          ref={dropdownEl}
          className={classnames({
            'progress-bar__steps': true,
            'progress-bar__steps--show-dropdown': showDropdown
          })}
        >
          {stepsData.map((step, index) => (
            <button
              key={step.value}
              disabled={
                (!step.done && !step.active) ||
                index === 0 ||
                index === 1 ||
                (isDecreasingJumpers &&
                  (step.value === 'review-order' ||
                    step.value === 'checkout' ||
                    step.value === 'select-date-time'))
              }
              className={classnames({
                'progress-bar__step': true,
                'progress-bar__step--active': step.active,
                'progress-bar__step--done': step.done,
                'progress-bar__step--disabled':
                  (!step.done && !step.active) ||
                  (isDecreasingJumpers &&
                    (step.value === 'review-order' ||
                      step.value === 'checkout' ||
                      step.value === 'select-date-time')),
                'progress-bar__step--not-clickable':
                  index === 0 ||
                  index === 1 ||
                  (isEditMode && index === 5) ||
                  (isEditMode && index === 6) ||
                  (isDecreasingJumpers &&
                    (step.value === 'review-order' ||
                      step.value === 'checkout' ||
                      step.value === 'select-date-time'))
              })}
              onClick={() => handleStepClick(step)}
            >
              <>
                {getStepIcon(step, index)}
                <span className="progress-bar__step__heading">{getStepLabel(step)}</span>
              </>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ProgressBar;
