import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalContext, ModalContextValueType } from '../contexts/modal';

import ReviewTable from '../components/ReviewTable';
import Button from '../components/Button';

import { DataContext } from '../contexts/data';
import type { DataContextValueType } from '../contexts/data';

import { StepsContext } from '../contexts/steps';
import type { StepsContextValueType } from '../contexts/steps';

function ReviewPage() {
  const {
    productId: [productId],
    bookingData: [bookingData],
    bookingItems: [bookingItems],
    bookingDetails: [bookingDetails],
    editPackage: [editPackage],
    venueInfo,
    getInitialEditingData
  } = useContext(DataContext) as DataContextValueType;
  const { showLoading, hideLoading } = useContext(ModalContext) as ModalContextValueType;
  const { setAllStepsDone, goToStep } = useContext(StepsContext) as StepsContextValueType;

  const [lessThanFiveDays, setLessThanFiveDays] = useState<boolean>(false);
  const [isPastEvent, setIsPastEvent] = useState<boolean>(false);
  const [isBookingCanceled, setIsBookingCanceled] = useState<boolean>(false);

  const navigate = useNavigate();

  const getDateFormated = (date: string) => {
    const splitedDate = date.split('-');

    const month = parseInt(splitedDate[1], 10) - 1; // Months are 0-based (0 = January)
    const day = parseInt(splitedDate[2], 10);
    const year = parseInt(splitedDate[0], 10);

    const d = new Date(year, month, day);

    const formatedDate = d.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric'
    });

    return formatedDate;
  };

  const getTimeFormated = (date: string) => {
    const d = new Date(date);

    return d.toLocaleDateString('en-US', {
      hour12: true,
      hour: 'numeric',
      minute: 'numeric'
    });
  };

  const setBookingStatus = () => {
    const bookingStatus = bookingDetails?.status;
    const bookingDate = new Date(bookingData.extra?.bookingDate || '');
    const today = new Date();
    const fiveDaysFromNow = new Date();
    fiveDaysFromNow.setDate(today.getDate() + 5);

    if (bookingStatus === 'Cancelled') {
      setIsBookingCanceled(true);
      return;
    }

    if (bookingDate < today) {
      setIsPastEvent(true);
    } else if (bookingDate < fiveDaysFromNow) {
      setLessThanFiveDays(true);
    }
  };

  const getTitle = () => {
    if (isBookingCanceled) return <b>Your reservation has been cancelled.</b>;
    return <b>Want to change your reservation?</b>;
  };

  const getDescription = () => {
    if (isPastEvent)
      return (
        <span>
          Thanks for partying with us. If you’d like to book another event, please visit{' '}
          <a href="#" target="_blank">
            link
          </a>
        </span>
      );
    if (lessThanFiveDays)
      return (
        <span>
          We can’t wait to party with you! You’re 5 or less days away from your event, if you’d like
          to make changes give us a call {bookingData.extra.parkPhoneNumber}.
        </span>
      );
    if (isBookingCanceled)
      return (
        <span>
          If you did not intend to cancel please give us a call {bookingData.extra.parkPhoneNumber}
        </span>
      );
    return (
      <span>
        You can edit your reservation up to 5 days before the event. Don’t see the changes you want
        to make listed on this page? Give us a call! {bookingData.extra.parkPhoneNumber}
      </span>
    );
  };

  const handleGoToStep = (stepValue: string) => {
    navigate({
      pathname: '/',
      search: window.location.search
    });
    goToStep(stepValue);
  };

  const retrieveInitialEditingData = async () => {
    await getInitialEditingData();
    hideLoading();
  };

  useEffect(() => {
    if (productId && !bookingDetails) {
      retrieveInitialEditingData();
    }
  }, [productId, bookingDetails]);

  useEffect(() => {
    if (bookingData.extra.bookingDate) {
      setBookingStatus();
    }
  }, [bookingData]);

  useEffect(() => {
    if (!editPackage) {
      setAllStepsDone();
    } else {
      retrieveInitialEditingData();
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 250);

    showLoading();
  }, []);

  return (
    <div className="review-page">
      <div className="container-sm step-container__header">
        <h1 className="step-container__heading">{bookingDetails?.name || 'Your party'}</h1>
        {bookingData.extra?.bookingDate && (
          <p className="step-container__description">
            The reservation details for {bookingDetails?.name} on{' '}
            {getDateFormated(`${bookingData.extra?.bookingDate}`)} at{' '}
            {
              getTimeFormated(
                `${bookingData.extra?.bookingDate} ${bookingData.extra?.startTime}`
              ).split(',')[1]
            }{' '}
            at our {venueInfo.name} location are listed below.
          </p>
        )}
      </div>
      <div className="container-sm">
        <div className="steps-warning-message">
          <p className="steps-warning-message__text">{getTitle()}</p>
          <p className="steps-warning-message__text">{getDescription()}</p>
        </div>
      </div>
      <div className="container-sm review-page__buttons-wrapper">
        {!isPastEvent && !lessThanFiveDays && !isBookingCanceled && (
          <>
            <Button kind="outline" onClick={() => handleGoToStep('select-date-time')}>
              Edit Party Date & Time
            </Button>
            <Button kind="outline" onClick={() => handleGoToStep('enter-guest-details')}>
              Edit Guest Count
            </Button>
            <Button kind="outline" onClick={() => handleGoToStep('select-addons')}>
              Edit Food & Add-ons
            </Button>
          </>
        )}
      </div>

      {bookingItems.length > 0 && (
        <>
          <div className="container-sm">
            <ReviewTable />
          </div>
          {!isPastEvent && !lessThanFiveDays && !isBookingCanceled && (
            <div className="container-sm review-page__buttons-wrapper-bottom">
              <Button kind="outline" onClick={() => handleGoToStep('select-date-time')}>
                Edit Party Date & Time
              </Button>
              <Button kind="outline" onClick={() => handleGoToStep('enter-guest-details')}>
                Edit Guest Count
              </Button>
              <Button kind="outline" onClick={() => handleGoToStep('select-addons')}>
                Edit Food & Add-ons
              </Button>
            </div>
          )}
          <img
            className="review-page__confetti-1"
            src="/images/confetti.png"
            width={161}
            height={180}
            alt="confetti"
          />
          <img
            className="review-page__confetti-2"
            src="/images/confetti.png"
            width={161}
            height={180}
            alt="confetti"
          />
          <img
            className="review-page__confetti-3"
            src="/images/confetti.png"
            width={161}
            height={180}
            alt="confetti"
          />
          <img
            className="review-page__confetti-4"
            src="/images/confetti.png"
            width={161}
            height={180}
            alt="confetti"
          />
        </>
      )}
    </div>
  );
}

export default ReviewPage;
