import React, { useContext } from 'react';

import { DataContext } from "../../../contexts/data";
import type { DataContextValueType } from "../../../contexts/data";

const CheckoutDescription = () => {
  const {
    bookingData: [bookingData],
    partyNameEl,
  } = useContext(DataContext) as DataContextValueType;

  return (
    <div className="container-sm step-container__subheader">
      <p className="step-container__description"><span>In order to reserve {partyNameEl} we need to collect your ${bookingData.extra.prices?.amountOwing?.toFixed(2)} deposit. Please fill out your payment information.</span></p>
    </div>
  );
};

export default CheckoutDescription;
