export type IncludedAddOnsCategories = {
  food: string[];
  drinks: string[];
  extra: string[];
};

export type OptionalAddOnsCategories = {
  food: string[];
  decoration: string[];
  extra: string[];
};

export const includedAddonCategories: IncludedAddOnsCategories = {
  food: ['Included Party Items - Pizza'],
  drinks: ['Included Party Items - Drinks'],
  extra: [],
};

export const optionalAddonCategories: OptionalAddOnsCategories = {
  food: ['Party Platters'],
  decoration: ['Additional Party Pizza'],
  extra: [],
};