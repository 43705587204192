import React from 'react';

import type { Prices } from '../../../contexts/data';

type ReviewPricesProps = {
  prices: Prices;
};

const ReviewPrices = ({ prices }: ReviewPricesProps) => {
  return (
    <>
      <div className="review-step__total">
        <div className="review-step__total-row">
          <p className="total-row__total-label">Subtotal</p>
          <p className="total-row__total-value">
            ${((prices.total || 0) - (prices.tax || 0) + (prices.discount || 0)).toFixed(2)}
          </p>
        </div>
        {!!prices?.discount && (
          <div className="review-step__total-row">
            <p className="total-row__total-label">Discount</p>
            <p className="total-row__total-value">- ${prices?.discount?.toFixed(2)}</p>
          </div>
        )}
        <div className="review-step__total-row">
          <p className="total-row__total-label">Tax</p>
          <p className="total-row__total-value">${prices.tax?.toFixed(2)}</p>
        </div>
        <div className="review-step__total-row">
          <p className="total-row__total-label">Total</p>
          <p className="total-row__total-value">${prices.total?.toFixed(2)}</p>
        </div>
      </div>
      <div className="review-step__due-today">
        <p className="total-label">Deposit Due Today</p>
        <p className="total-value">${prices.amountOwing?.toFixed(2)}</p>
      </div>
    </>
  );
};

export default ReviewPrices;
