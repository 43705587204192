import React, { useContext } from 'react';

import { DataContext } from '../../../contexts/data';
import type { DataContextValueType } from '../../../contexts/data';

import Button from '../../../components/Button';
import Svg from '../../../components/Svg';

type AddonsButtonsProps = {
  handlePrev: () => void;
  handleNext: () => void;
  nextDisabled: boolean;
};

const AddonsButtons = ({ handlePrev, handleNext, nextDisabled }: AddonsButtonsProps) => {
  const { isEditMode } = useContext(DataContext) as DataContextValueType;

  return (
    <div className="step-container__buttons step-container__buttons--gray-bgr">
      <div className="container">
        {!isEditMode && (
          <Button onClick={handlePrev} kind="ghost" leftIcon={<Svg content="arrow-left" />}>
            Back
          </Button>
        )}
        <Button
          onClick={handleNext}
          disabled={nextDisabled}
          rightIcon={<Svg content="double-chevron-right" />}
        >
          {isEditMode ? 'Save changes' : 'Next: REVIEW YOUR ORDER'}
        </Button>
      </div>
    </div>
  );
};

export default AddonsButtons;
