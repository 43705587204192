import React, { useContext } from 'react';

import { DataContext } from '../../../contexts/data';
import type { DataContextValueType } from '../../../contexts/data';

const CeckoutPrices = () => {
  const {
    bookingData: [bookingData]
  } = useContext(DataContext) as DataContextValueType;

  return (
    <div className="checkout__price-details">
      {!!bookingData.extra.discounts?.length && (
        <div className="checkout__promo">
          <h6>Promo Code</h6>
          <span>{bookingData.extra.discounts[0]?.code}</span>
        </div>
      )}

      <div className="checkout__subtotal">
        <div className="checkout__subtotal__row">
          <span>Subtotal</span>
          <strong>
            $
            {(
              (bookingData.extra.prices?.total || 0) -
              (bookingData.extra.prices?.tax || 0) +
              (bookingData.extra.prices?.discount || 0)
            ).toFixed(2)}
          </strong>
        </div>

        {!!bookingData.extra.prices?.discount && (
          <div className="checkout__subtotal__row">
            <span>Discount</span>
            <strong>- ${bookingData.extra.prices?.discount.toFixed(2)}</strong>
          </div>
        )}

        <div className="checkout__subtotal__row">
          <span>Tax</span>
          <strong>${bookingData.extra.prices?.tax?.toFixed(2)}</strong>
        </div>

        <div className="checkout__subtotal__row">
          <span>Total</span>
          <strong>${bookingData.extra.prices?.total?.toFixed(2)}</strong>
        </div>
      </div>

      <div className="checkout__total checkout__subtotal__row">
        <strong>Due Today</strong>
        <strong>${bookingData.extra.prices?.amountOwing?.toFixed(2)}</strong>
      </div>
    </div>
  );
};

export default CeckoutPrices;
