import React, { useState, useRef, useEffect } from 'react';
import type { ReactElement } from "react";

export type AccordionData = {
  heading: string;
  copy: ReactElement;
}

function Accordion({ heading, copy }: AccordionData) {
  const [isActive, setIsActive] = useState(false);
  const contentEl = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isActive && contentEl.current) {
      contentEl.current.focus();
    }
  }, [isActive]);


  return (
    <div className={`accordion${isActive ? ' accordion--active' : ''}`}>
      <button className="accordion__heading-container" onClick={() => setIsActive(!isActive)} role="button" aria-expanded={isActive} aria-label={heading}>
        <h3 className="accordion__heading">{heading}</h3>
        <img className="accordion__icon" src={isActive ? '/icons/minus.svg' : '/icons/plus.svg'} alt={isActive ? 'collapse' : 'open'} width="20" height="20" />
      </button>
      <div className="accordion__panel" style={{ blockSize: isActive && contentEl.current ? (contentEl.current as HTMLElement).offsetHeight : 0 }}>
        <div className="accordion__content" tabIndex={0} ref={contentEl} role="region" aria-label={copy.props.children} aria-expanded={isActive}>
          {copy}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
