import React, { useContext } from "react";
import type { AccordionData } from "../components/Accordion";

import { DataContext } from '../contexts/data';
import type { DataContextValueType } from '../contexts/data';

import Accordion from "../components/Accordion";

function Faq() {
  const {
    bookingData: [bookingData],
  } = useContext(DataContext) as DataContextValueType;

  const waiverLink = bookingData.extra.rollerVenueName ? `https://waiver.haveablast.roller.app/${bookingData.extra.rollerVenueName}/` : `https://www.skyzone.com/#waiver-popup`;

  const faqData: AccordionData[] = [
    {
      heading: 'Who should I include as a party jumper?',
      copy: <p>Any Guests that will be joining the Guest of Honor and participating on the attractions in the park during play time! If you have Guests that will just be observing, they don´t need to be included in the jumper count.</p>
    },
    {
      heading: 'Can I add jumpers after I book the party?',
      copy: <p>Absolutely!  If you would like to add on additional jumpers or make changes to the type of party package you booked it’s best to communicate with us as soon as possible. We cannot guarantee upgrade availability. We encourage you to book for the larger package initially, and then let us know at least 3 days in advance of the party if you’d prefer to switch to the smaller package.</p>
    },
    {
      heading: 'Is the guest of honor included in the count?',
      copy: <p>Yes, the birthday child is included in the birthday jumper count for the party. This ensures that they get SkySocks and a wristband to participate on all of the Sky Zone attractions during the party.</p>
    },
    {
      heading: 'How old do I have to be to have a party in Sky Zone?',
      copy: <p>All ages are welcome.</p>
    },
    {
      heading: 'When should everyone arrive for the party?',
      copy: <p>All your guests will need to arrive 30 minutes prior to your party start time. This allows time for the entire group to get through check-in, verifying waivers and storing any personal items.</p>
    },
    {
      heading: 'What does the Party Host do?',
      copy: <p>Party Hosts will do everything to make your party room time a seamless experience, including set-up, clean-up, serving food and drinks, leading the birthday song and making the party room time fun and interactive!</p>
    },
    {
      heading: 'Who needs a waiver and how do we sign?',
      copy: <p>All jumpers and observers entering the attractions area must have a signed waiver on file. Waivers can be completed online before your party by clicking <a href={waiverLink} target="_blank">here</a>.</p>
    },
    {
      heading: 'Can I add extra jump time?',
      copy: <p>Yes!  We encourage parties to add on additional jump time to enjoy our activities even more!  Just give our Events Team a call.  Additional jump time would be added to the front end of your party, which would make your party check-in time earlier.   Party room time would still be reserved for after jump time is complete.</p>
    },
    {
      heading: 'Is there a party discount for Members?',
      copy: <p>Yes!  Elite Members receive a discount on regular priced birthday parties.  Ask our Event Team for details to apply your Member Discount.</p>
    },
    {
      heading: 'Is a deposit required to make a party reservation? What if I need to cancel?',
      copy: <p>A minimum deposit of $75 is required to complete your booking. Deposits for last minute parties (within 72 hours) are non-refundable.  Deposits for all other parties are fully refundable within the first 72 hours of booking. Your deposit will be applied towards your total party on the day of the event.</p>
    },
    {
      heading: 'Are socks required to jump?',
      copy: <p>Yes. We sell special SkySocks in the park as both a safety and cleanliness measure- plus, you get to keep them after your visit! Because who wants to jump in used socks, anyway?</p>
    }
  ]

  return (
    <section className="faq container" id="faq">
      <div className="faq__intro">
        <h2 className="faq__title">FAQs</h2>
        <p>We'd never want to leave you hanging with a question. Check out our FAQs for detailed information about our policies, party packages, and more, and if you don't see the answer to your question there, feel free to give your local park a call for extra support. </p>
      </div>
      <div className="faq__items">
        {faqData.map(item => <Accordion key={item.heading} heading={item.heading} copy={item.copy} />)}
      </div>
    </section>
  );
}


export default Faq;
