import React, { useContext, useEffect } from 'react';

import Button from '../Button';
import Svg from '../Svg';
import Cookies from 'js-cookie';
import { DataContext } from '../../contexts/data';
import type { DataContextValueType } from '../../contexts/data';

type ButtonTypes = 'exitBooking' | 'reload' | 'close';

type ErrorAlertProps = {
  onCloseModal: () => void;
  title: string;
  buttons?: ButtonTypes[];
  description?: string;
};

function ErrorAlert({ onCloseModal, title, description, buttons }: ErrorAlertProps) {
  const {
    isEditMode,
    bookingData: [bookingData]
  } = useContext(DataContext) as DataContextValueType;

  const handleExitBooking = () => {
    window.location.href = document.referrer || 'https://www.skyzone.com/parties/';
  };

  const handleReload = () => {
    if (isEditMode) {
      window.location.href = `${window.location.origin}/review${window.location.search}`;
      return;
    }

    window.location.reload();
  };

  const getButton = (button: string, index: number) => {
    if (!button) return null;

    if (button === 'exitBooking') {
      return (
        <Button
          key={index}
          rightIcon={index === 0 ? <Svg content="double-chevron-right" /> : null}
          leftIcon={index === 1 ? <Svg content="double-chevron-left" /> : null}
          kind={index === 0 ? 'primary' : 'ghost'}
          onClick={handleExitBooking}
        >
          Exit Booking
        </Button>
      );
    }

    if (button === 'reload') {
      return (
        <Button
          key={index}
          rightIcon={index === 0 ? <Svg content="double-chevron-right" /> : null}
          leftIcon={index === 1 ? <Svg content="double-chevron-left" /> : null}
          kind={index === 0 ? 'primary' : 'ghost'}
          onClick={handleReload}
        >
          Restart booking
        </Button>
      );
    }

    if (button === 'close') {
      return (
        <Button
          key={index}
          rightIcon={index === 0 ? <Svg content="double-chevron-right" /> : null}
          leftIcon={index === 1 ? <Svg content="double-chevron-left" /> : null}
          kind={index === 0 ? 'primary' : 'ghost'}
          onClick={onCloseModal}
        >
          Close
        </Button>
      );
    }

    return null;
  };

  const showCloseButton = buttons?.includes('close');

  useEffect(() => {
    //error alert is shown, remove extra park info from local storage and all cookies set by the app
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('extraParkInfo')) {
        localStorage.removeItem(key);
      }
    }
    const cookies = Cookies.get();
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }
    //clear session storage
    sessionStorage.clear();
  }, []);

  return (
    <div className="error-alert">
      <header className="error-alert__header">
        <img className="error-alert__header__icon" src="/icons/warning-exagon.svg" alt="warning" />
        <h3 className="error-alert__header__title">{title}</h3>
        {showCloseButton && (
          <button className="error-alert__header__close" aria-label="close" onClick={onCloseModal}>
            <img src="/icons/close.svg" alt="close" />
          </button>
        )}
      </header>
      <div className="error-alert__content">
        <p className="error-alert__text">
          {description && <span>{description}</span>}
          <span>
            You partied too hard! Unfortunately we’re having trouble processing your request online.
            Give our Guest Service Team a call at {bookingData.extra.parkPhoneNumber} and we’re
            happy to help customize your party experience!
          </span>
        </p>
        <div className="error-alert__buttons-container">
          {buttons && buttons.map((button, index) => getButton(button, index))}
        </div>
      </div>
    </div>
  );
}

export default ErrorAlert;
