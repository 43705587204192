import React, { useState, useEffect } from 'react';
import Button from '../Button';
import Svg from '../Svg';
import Cookies from 'js-cookie';

type ButtonType = 'exit';

type RedirectWarningProps = {
  onCloseModal: () => void;
  title: string;
  button: ButtonType;
  description?: string;
};

function RedirectWarning({ title, description, button }: RedirectWarningProps) {
  const [timeLeft, setTimeLeft] = useState(5);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const handleExitBooking = () => {
    if (timerId) {
      clearInterval(timerId);
    }
    requestAnimationFrame(() => {
      window.location.href = 'https://www.skyzone.com/locations/parties/';
    });
  };

  const getButton = (button: string) => {
    if (!button) return null;
    if (button === 'exit') {
      return (
        <Button
          leftIcon={<Svg content="double-chevron-left" />}
          kind={'ghost'}
          onClick={handleExitBooking}
        >
          Select My Park
        </Button>
      );
    }

    return null;
  };

  useEffect(() => {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && key.startsWith('extraParkInfo')) {
        localStorage.removeItem(key);
      }
    }
    const cookies = Cookies.get();
    for (const cookie in cookies) {
      Cookies.remove(cookie);
    }
    sessionStorage.clear();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft <= 0.25) {
          clearInterval(timer);
          handleExitBooking();
          return 0;
        }
        return prevTimeLeft - 0.25;
      });
    }, 250);

    setTimerId(timer);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="redirect-warning">
      <header className="redirect-warning__header">
        <h3 className="redirect-warning__header__title">{title}</h3>
        <span className="redirect-warning__timer">{`0:${timeLeft < 10 ? '0' : ''}${Math.ceil(
          timeLeft
        )}`}</span>
      </header>
      <div className="redirect-warning__content">
        <p className="redirect-warning__text">{description && <span>{description}</span>}</p>
        <div className="redirect-warning__buttons-container">{button && getButton(button)}</div>
      </div>
    </div>
  );
}

export default RedirectWarning;
