import React, { useContext, useState } from 'react';

import { DataContext } from '../../contexts/data';
import type { DataContextValueType } from '../../contexts/data';

import { ModalContext } from '../../contexts/modal';
import type { ModalContextValueType } from '../../contexts/modal';

import Button from '../Button';
import ErrorAlert from './Error';
import Svg from '../Svg';

import API from '../../api/api';

type TimeOverAlertProps = {
  onCloseModal: () => void;
};

function TimeOverAlert({ onCloseModal }: TimeOverAlertProps) {
  const {
    bookingData: [bookingData, setBookingData],
    paymentReady: [paymentReady],
    bookingItems: [bookingItems],
    isEditMode,
    selectedPackage
  } = useContext(DataContext) as DataContextValueType;

  const {
    showLoading,
    hideLoading,
    open: openModal,
    close: closeModal
  } = useContext(ModalContext) as ModalContextValueType;

  const [errorMessage, setErrorMessage] = useState('');

  const handleExitBooking = () => {
    window.location.href = document.referrer || 'https://www.skyzone.com/parties/';
  };

  const getPackageItem = () => {
    return {
      productId: selectedPackage?.products[0].id || '',
      quantity: bookingData.extra.jumpersNumber,
      bookingDate: bookingData.extra.bookingDate,
      startTime: bookingData.extra.startTime,
      name: selectedPackage?.products[0].name || '',
      parentName: selectedPackage?.name || '',
      cost: selectedPackage?.products[0].cost || 0,
      tax: selectedPackage?.products[0].tax || 0,
      parentId: selectedPackage?.id || '',
      partyPackageInclusions: bookingItems.filter(bookingItem => bookingItem.isIncluded)
    };
  };

  const getBookingPayload = () => {
    const packageItem = getPackageItem();
    const filteredBookingItems = bookingItems.filter(bookingItem => !bookingItem.isIncluded);
    const items = [...filteredBookingItems, packageItem];
    const payload = {
      items
    };
    return payload;
  };

  const handleReload = async () => {
    showLoading();

    if (bookingData.extra.capacityReservationId && !isEditMode) {
      await API.deleteCapacityReservation(bookingData.extra.capacityReservationId);
    }

    if (isEditMode) {
      window.location.href = `${window.location.origin}/review${window.location.search}`;
      return;
    }

    window.location.reload();
  };

  const reStartSession = async () => {
    showLoading();

    try {
      if (bookingData.extra.capacityReservationId) {
        await API.deleteCapacityReservation(bookingData.extra.capacityReservationId);
      }

      const capacityReservationPayload = getBookingPayload();
      const capacityReservationRes = await API.capacityReservation(capacityReservationPayload);

      setBookingData({
        ...bookingData,
        extra: {
          ...bookingData.extra,
          capacityReservationId: capacityReservationRes.uniqueId
        }
      });

      if (capacityReservationRes.errors) {
        setErrorMessage(capacityReservationRes.errors[0].message);
        return;
      }

      onCloseModal();
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  return (
    <div className="time-over-alert">
      <header className="time-over-alert__header">
        <h3 className="time-over-alert__header__title">Session Time Out</h3>
      </header>
      <div className="time-over-alert__content">
        <picture className="time-over-alert__bgr">
          <source srcSet="/images/alerts/confetti-desk.svg" media="(min-width: 768px)" />
          <img src="/images/alerts/confetti-mob.svg" alt="confetti" />
        </picture>
        <p className="time-over-alert__text">
          {errorMessage ? (
            <span>{errorMessage}</span>
          ) : (
            <>
              <span>Your session has expired!</span>
              {!paymentReady && <span>Would you like to extend your time?</span>}
            </>
          )}
        </p>
        <div className="time-over-alert__buttons-container">
          {!errorMessage && !paymentReady && (
            <Button kind="secondary" onClick={reStartSession}>
              Extend my time
            </Button>
          )}
          {errorMessage ||
            (paymentReady && (
              <Button kind="secondary" onClick={handleReload}>
                Restart booking
              </Button>
            ))}
          <Button
            onClick={handleExitBooking}
            kind="ghost"
            leftIcon={<Svg content="double-chevron-left" />}
          >
            Exit Booking
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TimeOverAlert;
