import React, { useContext } from "react";
import type { MouseEvent } from "react";

import { DataContext } from "../contexts/data";
import type { DataContextValueType } from "../contexts/data";

import { StepsContext } from "../contexts/steps";
import type { StepsContextValueType } from "../contexts/steps";

function Header() {
  const {
    venueInfo,
    bookingData: [bookingData],
  } = useContext(DataContext) as DataContextValueType;

  const {
    steps: [stepsData],
  } = useContext(StepsContext) as StepsContextValueType;

  const handleExitBooking = (ev: MouseEvent<HTMLElement>) => {
    ev.preventDefault();
    window.location.href = document.referrer || 'https://www.skyzone.com/parties/';
  }

  return (
    <header className="header">
      <div className="header__content">
        <div className="header__content__container">
          <div className="header__logo-container">
            <a href="https://www.skyzone.com/parties/" onClick={handleExitBooking}>
              <picture className="header__logo">
                <img src="/images/logo-skyzone.svg" width="280" height="38" alt="Skyzone" loading="lazy" />
              </picture>
            </a>
          </div>

          <div className="header__info">
            <span className="header__info__location">{venueInfo.name}</span>
            {(window.location.pathname !== '/review' && window.location.pathname !== '/confirmation' && !stepsData.find((step) => step.value === 'checkout')?.done) && (
              <>
                <span className="header__info__separator"></span>
                <span className="header__info__subtotal">Subtotal: ${bookingData.extra.totalCost.toFixed(2)}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}


export default Header;
