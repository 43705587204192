import React, { useContext } from 'react';

import { DataContext } from "../../../contexts/data";
import type { DataContextValueType } from "../../../contexts/data";

const AddonsDescription = () => {
  const {
    bookingData: [bookingData],
    partyNameEl,
  } = useContext(DataContext) as DataContextValueType;

  const buildDescription = () => {
    const { bookingDate, startTime } = bookingData.extra;

    const time = startTime.split(':');
    const hours = Number(time[0]);
    const minutes = Number(time[1]);
    let timeValue;

    if (hours > 0 && hours <= 12) {
      timeValue= "" + hours;
    } else if (hours > 12) {
      timeValue= "" + (hours - 12);
    } else if (hours === 0) {
      timeValue= "12";
    }

    timeValue += (minutes < 10) ? ":0" + minutes : ":" + minutes;
    timeValue += (hours >= 12) ? " pm" : " am";

    const splitedBookingDate = bookingDate.split('-');
    const formatedBookingDate = `${splitedBookingDate[1]}/${splitedBookingDate[2]}/${splitedBookingDate[0]}`;

    return <span>{partyNameEl} on <strong>{formatedBookingDate}</strong> at <strong>{timeValue}</strong> is going to be a blast!</span>;
  }


  return (
    <div className="container-sm step-container__subheader">
      <p className="step-container__description">{buildDescription()}</p>
    </div>
  );
};

export default AddonsDescription;
