import React from 'react';
import type { ReactElement, MouseEvent } from 'react';
import ReactDOM from 'react-dom';

type ModalProps = {
  isShowing: boolean;
  content: ReactElement;
  onCloseModal: () => void;
  closeOnClickOutside: boolean;
}

const Modal = ({ isShowing, content, onCloseModal, closeOnClickOutside }: ModalProps) => {
  const handleClickOuside = (ev: MouseEvent<HTMLElement>) => {
    const target = ev.target;
    if ((target as HTMLElement).classList.contains('modal__wrapper')) {
      if (closeOnClickOutside) onCloseModal();
    }
  }

  return isShowing ? ReactDOM.createPortal(
    <>
      <div className="modal__overlay" />
      <div className="modal__wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog" onClick={handleClickOuside}>
       {content}
      </div>
    </>, document.body
  ) as JSX.Element : null;
}

export default Modal;