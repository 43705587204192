import React from 'react';

import Button from '../../../components/Button';
import Svg from '../../../components/Svg';

type ReviewButtonsProps = {
  handlePrev: () => void;
  handleNext: () => void;
};

const ReviewButtons = ({ handlePrev, handleNext }: ReviewButtonsProps) => {
  return (
    <div className="step-container__buttons step-container__buttons--review container-sm">
      <div>
        <Button onClick={handlePrev} kind="ghost" leftIcon={<Svg content="arrow-left" />}>
          Back
        </Button>
        <Button onClick={handleNext} rightIcon={<Svg content="double-chevron-right" />}>
          CONTINUE TO DEPOSIT
        </Button>
      </div>
    </div>
  );
};

export default ReviewButtons;
