import React from 'react';
import ReactDOM from 'react-dom';

type LoadingProps = {
  isShowing: boolean;
}

const Loading = ({ isShowing }: LoadingProps) => {

  return isShowing ? ReactDOM.createPortal(
    <>
      <div className="loading__overlay" />
      <div className="loading__wrapper" aria-hidden>
        <div className="loading__spinner" />
      </div>
    </>, document.body
  ) as JSX.Element : null;
}

export default Loading;