import React  from 'react';

const ReviewDescription = () => {
  return (
    <div className="container-sm step-container__subheader">
      <p className="step-container__description"></p>
    </div>
  );
};

export default ReviewDescription;
