import SvgContent from './SvgContent';
import type SvgMap from './SvgMap.d';

type SvgProps = {
  content: SvgMap;
};

const Svg = ({ content }: SvgProps) => {
  return SvgContent[content];
};

export default Svg;
